import React, { useState, useRef } from "react";
import classNames from "classnames/bind";
import CTA from "@v4/talend/src/components/cta/Cta";
import isDesktopQuery from "../../../utils/mediaQueries";
import { useMediaQuery } from "react-responsive";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/talend/src/utils/formatContentfulRichText";
import { useI18n } from "@v4/utils/i18nContext";
import { pricingPackageTranslations } from "../../../translations/pricingPackage";
import scrollWithHeaderOffset from "@v4/utils/scrollWithHeaderOffset";
import * as packageStyles from "./pricingPackage.module.css";

// Symbols for accordion elements
import MinusSVG from "@v4/gatsby-theme-talend/src/assets/svg/minus-symbol--circle.inline.svg";
import PlusSVG from "@v4/gatsby-theme-talend/src/assets/svg/plus-symbol--circle.inline.svg";

const cx = classNames.bind(packageStyles);

const PricingPackage = (props) => {
    // Ref for the package element
    const packageRef = useRef();

    // Ref for the features sub-element
    const featuresWrapperRef = useRef();

    // State used for the accordion
    const [featuresOpen, setFeaturesOpen] = useState(false);

    // Media query for desktop
    const isDesktop = useMediaQuery(isDesktopQuery, undefined);

    const { i18n } = useI18n();

    // Accordion Function
    const featuresAccordion = () => {
        // Get the wrapper element
        const featWrapEl = featuresWrapperRef.current;

        // Get the full height of the features <ul>
        const fullHeight = `${featWrapEl.firstChild.scrollHeight}px`;

        // Set the initial height for the CSS transition
        featWrapEl.style.height = fullHeight;

        // Scroll the features into view with a -20px offset
        scrollWithHeaderOffset(featuresWrapperRef.current, 500, -20);

        // If the features are open, close them
        if (featuresOpen) {
            // Closing: At the next frame, reset the height
            requestAnimationFrame(() => {
                featWrapEl.style.height = null;

                // Scroll to the corresponding package with a -20px offset
                scrollWithHeaderOffset(packageRef.current, 500, -20);
            });
        }

        // Update state to match the Accordion
        setFeaturesOpen(!featuresOpen);
    };

    // Set up variation class (ex: Stitch package)
    const variationClass = props.variation ? String(props.variation).toLowerCase() : "";

    return (
        <div className={`${cx("pricingPackage", variationClass)} pricingPackage`} ref={packageRef}>
            {props.title && (
                <div className={`${cx("packageTitle")} packageTitle`}>
                    {renderRichText(props.title, formatContentfulRichText())}
                </div>
            )}
            {props.description && (
                <div className={`${cx("packageDescription")} packageDescription`}>
                    {renderRichText(props.description, formatContentfulRichText())}
                </div>
            )}
            {props.ctas && (
                <div className={cx("packageCTAs")}>
                    {props.ctas.map((cta) => (
                        <CTA key={cta.id} {...cta} customClass={"pricingCTA"} />
                    ))}
                </div>
            )}
            {(props.enabledFeatures || props.disabledFeatures) && (
                <>
                    <div className={cx("packageFeaturesWrapper")} ref={featuresWrapperRef}>
                        <div>
                            {props.enabledFeatures && (
                                <div className={cx("packageFeatures", "enabledFeatures")}>
                                    {renderRichText(props.enabledFeatures, formatContentfulRichText())}
                                </div>
                            )}
                            {props.disabledFeatures && (
                                <div className={cx("packageFeatures", "disabledFeatures")}>
                                    {renderRichText(props.disabledFeatures, formatContentfulRichText())}
                                </div>
                            )}
                        </div>
                    </div>
                    <button
                        className={cx("featuresToggle")}
                        onClick={(e) => {
                            if (isDesktop === false) {
                                featuresAccordion();
                            }
                        }}
                    >
                        {featuresOpen
                            ? pricingPackageTranslations[i18n.curr.langCode].hide
                            : pricingPackageTranslations[i18n.curr.langCode].show}
                        {featuresOpen ? <MinusSVG></MinusSVG> : <PlusSVG></PlusSVG>}
                    </button>
                </>
            )}
        </div>
    );
};

export default PricingPackage;
