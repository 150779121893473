import React, { useEffect } from "react";
import { graphql } from "gatsby";
import classNames from "classnames/bind";
import { useMediaQuery } from "react-responsive";
import { isMobileQuery } from "../../../utils/mediaQueries";
import * as pricingWrapperStyles from "./pricingWrapper.module.css";
import PricingPackage from "../pricingPackage/PricingPackage";

export const fragment = graphql`
    fragment PricingWrapper on ContentfulPricingWrapper {
        id
        pricingPackages {
            variation
            title {
                raw
            }
            description {
                raw
            }
            ctas {
                id
                ...Cta
            }
            enabledFeatures {
                raw
            }
            disabledFeatures {
                raw
            }
        }
    }
`;

// Equalize Title and Description heights
const handleResize = () => {
    // Acquire titles & descriptions
    const titles = document.getElementsByClassName("packageTitle");
    const descriptions = document.getElementsByClassName("packageDescription");

    // Equalize their heights
    equalizeHeight(titles);
    equalizeHeight(descriptions);
};

// Func which sets the height of all elements to the tallest of the set
const equalizeHeight = (elementSet) => {
    // Reset tallest height
    let tallest = 0;

    // Get the tallest height of the set
    Object.entries(elementSet).forEach(([key, el]) => {
        // Reset element height to acquire intrinsic content size
        el.style.height = "auto";

        // establish the tallest height value among the set
        if (el.clientHeight > tallest) tallest = el.clientHeight;
    });

    // Set the height of all elements to the tallest
    Object.entries(elementSet).forEach(([key, el]) => {
        el.style.height = `${tallest}px`;
    });
};

// Func which resets any inline height style for set of Els
const resetHeights = () => {
    const packageEls = document.querySelectorAll(".packageTitle, .packageDescription");
    Object.entries(packageEls).forEach(([key, el]) => {
        el.style.height = "auto";
    });
};

const PricingWrapper = (props) => {
    // Establish style bindings
    const cx = classNames.bind(pricingWrapperStyles);

    // Media query for mobile devices
    const isMobile = useMediaQuery(isMobileQuery, undefined);

    useEffect(() => {
        // As long as we're not on a mobile device -
        if (!isMobile) {
            // Equalize height on load and on resize
            handleResize();
            window.addEventListener("resize", handleResize);
        }
        return () => {
            // Reset heights of the titles and descriptions
            resetHeights();
            // Remove listener callback func and reset height on the elements
            window.removeEventListener("resize", handleResize);
        };
    }, [isMobile]);

    return (
        <div id={props.moduleId} className={cx("pricingWrapper")}>
            {props &&
                props.pricingPackages &&
                props.pricingPackages.map((pricingPackage, i) => {
                    return <PricingPackage {...pricingPackage} key={i} />;
                })}
        </div>
    );
};

export default PricingWrapper;
